
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCurrentUser } from "@/utils/index"
import { getAccountInfo, getMoreAccountInfo, getEDPLANPairCount} from "@/api/dashboard"
import ECP_EDP_DashboardNavigator from "@/components/cards/ECP_EDP_DashboardNavigator.vue"

export default defineComponent({
  name: "accountinfo",
  components: {
    ECP_EDP_DashboardNavigator
  },
  data() {
    return { 
      user:{},
      summary1: {},
      isLoading:true,
      pt : "",
      isShowMore :  true,
      summary2: {},
      ccode : 0,
      edplanpairs : 0,
      maxUturn : 0
    }
  },
  mounted(){
      this.user = getCurrentUser();
      this.getPT();
      this.getAccountInfo();
      this.getEDPLANPairCountNow();
  },
  methods : {
    async getAccountInfo(){
      this.isLoading = true;
      const response = await getAccountInfo();
      this.summary1 = response.data.summary1;
      this.ccode = response.data.ccode;
      this.isLoading = false;
    },
    async showMoreAccountInfo(){
      this.isLoading = true;
      const response = await getMoreAccountInfo();

      this.summary2 = response.data.summary1;
      this.isLoading = false;
      this.isShowMore = false;
    },
    async getEDPLANPairCountNow(){
      this.isLoading = true;
      const response = await getEDPLANPairCount();
      console.log(response.data);


      this.edplanpairs = response.data.edplanpairs;
      this.maxUturn = response.data.maxUturn;

      this.isLoading = false;
    },
    async getPT(){
       this.pt = String(localStorage.getItem("pt"));
    },
  },
  setup() {
    
  }
});
